import React, { useState, useEffect } from "react";
import { Grid, Typography, Container, Tooltip, Button } from "@mui/material";
import Loader from "../../Loader";
import moment from "moment";
import ApiCalls from "../../../api/customAxios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  getdays,
  formatDate,
  isMinutesOrHours,
} from "../../../helpers/helper-data";
import { ReactComponent as NotificationDefaultIcon } from "../../../assets/images/notification-default-icon.svg";
import { ReactComponent as MessageNavIcon } from "../../../assets/images/messageNav-icon.svg";
import { formatMuiErrorMessage } from "@mui/utils";
import { useSelector } from "react-redux";
import { isSessionValid } from "../../../helpers/helper";
import { danger } from "../../../Components/shared/messageBox";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as NoNotificationIcon } from "../../../assets/images/NoJobsFound.svg";

export default function NotificationList(props) {
  let navigate = useNavigate();
  const appOrigin = useSelector((state) => state.location.origin);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bodyPaddingTop, setBodyPaddingTop] = useState(false);
  const [allnotifications, setallnotifications] = useState([]);
  const [loadMore, setLoadMore] = React.useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedbutton, setselectedbutton] = useState("All");
  const [scrollnotifications, setScrollnotifications] = useState([]);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight * 0.8);

  useEffect(() => {
    isSessionValid().then((result) => {
      if (result === true) {
        getNotificationsDtoList();
      }
    });
  }, []);

  // const getNotificationsDtoList = () => {
  //   let mounted = true;
  //   ApiCalls.get("candidates/notifications/C/0/10")
  //     .then((response) => {
  //       if (mounted) {
  //         setNotifications(response.notificationsDtoList);
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch(function (error) {
  //     });
  //   return () => (mounted = false);
  // };
  function extractDateFromString(startDate, endDate) {
      const currentDate = moment().format("YYYY-MM-DD");
      const slotExpiry = moment(startDate).local().format("YYYY-MM-DD");
      let diffInMinutes = moment(endDate).local().diff(moment(), "minutes");
      let diffInDays = slotExpiry ? moment(currentDate).diff(moment(slotExpiry), 'days') : 0;
      return diffInDays <= 0 && diffInMinutes < 0;
};
const fetchMoreData = () => {
  let pgNo = pageNumber + 1;
  setPageNumber(pgNo);
  console.log("Inside fetchMoreData");
  let apiCall = "";
  if (selectedbutton === "All" && pgNo <= 4) {
      apiCall = () => ApiCalls.get(`candidates/notifications/C/${pgNo}/10`);
  } else if (selectedbutton === "Unread" && pgNo <= 4) {
      apiCall = () => ApiCalls.get("candidates/notifications/unread/C");
  }
  if (apiCall == "") {
    return;
  }
  apiCall()
      .then((response) => {
          const newNotifications = response.notificationsDtoList;
          if (response.notificationsDtoList.length < 10) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
          let startingindex = pgNo * 10;
          let endingindex = startingindex + 10;
          setIsLoading(false);
          if (selectedbutton == "All") {
          const updatedNotifications = [...allnotifications, ...newNotifications];
          setallnotifications(updatedNotifications);
          } else {
          const updatedNotifications = [...allnotifications, ...newNotifications.slice(startingindex, endingindex)];
          setallnotifications(updatedNotifications);
          }
      })
      .catch((error) => {
          console.error('Error fetching more notifications:', error);
          setIsLoading(false);
      });
};
const getNotificationsDtoList = () => {
  let mounted = true;
  setPageNumber(0);
  ApiCalls.get("candidates/notifications/C/0/10")
      .then((response) => {
          if (mounted) {
            setselectedbutton("All");
            if (response.notificationsDtoList.length < 10) {
              setLoadMore(false);
            } else {
              setLoadMore(true);
            }
              setallnotifications(response.notificationsDtoList);
          }
      })
      .catch(function (error) { });
  return () => (mounted = false);
};
const getUnreadNotificationsDtoList = () => {
  let mounted = true;
  setPageNumber(0);
  ApiCalls.get("candidates/notifications/unread/C")
      .then((response) => {
          if (mounted) {
            setselectedbutton("Unread");
            if (response.notificationsDtoList.length < 10) {
              setLoadMore(false);
            } else {
              setLoadMore(true);
            }
              // setallnotifications(response.notificationsDtoList);
              const firstTenNotifications = response.notificationsDtoList.slice(0, 10);
              setallnotifications(firstTenNotifications);
              props.setNotificationsCount(response.unReadNotifications);
          }
      })
      .catch(function (error) { });
  return () => (mounted = false);
};
const interviewDetails = (url, notification) => {
    let arr = url.split("/");
    let Url = `${process.env.REACT_APP_API_URL}candidates/interviews/schedules/${arr[arr?.length - 2]}`;
    ApiCalls.get(Url)
      .then((response) => {
        if (response?.interviewSchedulesDto?.length) {
           let scheduleRound = response?.interviewSchedulesDto[arr[arr?.length - 1] - 1];
          if (((scheduleRound?.ratingOfPanelByCandidate == null && response?.closureStatus != null) || scheduleRound?.ratingOfPanelByCandidate != null) && notification.notificationValue?.includes("interview feedback")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.status != "P" && notification.notificationValue?.includes("received an interview invite")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.status != "P" && notification.notificationValue?.includes("rescheduled")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.status != "X" && notification.notificationValue?.includes("cancelled")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.status == "A" && notification.notificationValue?.includes("upcoming interview") && extractDateFromString(scheduleRound?.scheduleStartDateTime, scheduleRound?.scheduleCompleteDateTime)) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (((scheduleRound?.status == "A" && scheduleRound?.roundCompleted == "Y") || scheduleRound?.status != "A") && notification.notificationValue?.includes("upcoming interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.ratingOfPanelByCandidate == null && notification.notificationValue?.includes("interview feedback") && response?.closureStatus == null) {
            navigate(url, { state: { isScorecard: true } });
          } else {
            navigate(url);
          }
        };
      })
      .catch((err) => {
        if (err?.statusCode != 500) {
          danger("Something went wrong");
        }
      });
  };
  const getUpdatedUnreadCount = () => {
    console.log("2-> Inside getUpdatedUnreadCount");
    ApiCalls.get("candidates/notifications/unread/C")
      .then((response) => {
        console.log("3->After API call");
        console.log(response.unReadNotifications);
        props.setNotificationsCount(response.unReadNotifications);
      })
      .catch(function (error) { });
  };
  const handleReadNotification = (notification) => {
    ApiCalls.put(`candidates/notifications/${notification.id}/R`)
    .then((response) => {
        getNotificationsDtoList();
        getUpdatedUnreadCount();
        setselectedbutton("All");
        props.setNotificationListDrawer(false);
    })
    .catch(function (error) { });
  };
  const handleNotificationNavigation = async(notification) => {
    setIsLoading(true);
    ApiCalls.put(`candidates/notifications/${notification.id}/R`)
  .then((response) => {
    // setNotificationsCount(0);
    getNotificationsDtoList();
    getUpdatedUnreadCount();
  })
  .catch(function (error) {});
const searchString = "/candidate";
if (notification.callToActionUrl.includes("jobId")) {
  if (notification.callToActionUrl.includes("Offered")) {
    const paramDelimiter = "&";
  const startIndex = notification.callToActionUrl.indexOf(searchString);
  const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
  const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
  const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
  const statusResponse = await handleJobsApiCall("F", match[1]);
  if ((statusResponse[0]?.candidateProcessStatus == null || statusResponse[0]?.candidateProcessStatus == "A" || statusResponse[0]?.candidateProcessStatus == "P") && statusResponse[0]?.enterpriseProcessStatus == "F") {
    const inputString = match[0];
    let jobIde = inputString.replace("&jobId=", "");
          let payLoadState = {
            jobId: jobIde,
            tabValue: match[1]
          };
    navigate(extractedString, { state: payLoadState });
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
  } else {
    danger("The notification you're trying to access has expired.");
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
    return;
  }
  } else if (notification.callToActionUrl.includes("Onboarded")) {
    const paramDelimiter = "&";
  const startIndex = notification.callToActionUrl.indexOf(searchString);
  const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
  const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
  const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
  const statusResponse = await handleJobsApiCall("O", match[1]);
  if (statusResponse[0]?.candidateProcessStatus == "P" && statusResponse[0]?.enterpriseProcessStatus == "O") {
    const inputString = match[0];
    let jobIde = inputString.replace("&jobId=", "");
          let payLoadState = {
            jobId: jobIde,
            tabValue: match[1]
          };
    navigate(extractedString, { state: payLoadState });
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
  } else {
    danger("The notification you're trying to access has expired.");
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
    return;
  }
  } else if (notification.callToActionUrl.includes("Offboarded")) {
    const paramDelimiter = "&";
  const startIndex = notification.callToActionUrl.indexOf(searchString);
  const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
  const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
  const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
  const statusResponse = await handleJobsApiCall("O", match[1]);
  if (statusResponse[0]?.candidateProcessStatus == null && statusResponse[0]?.enterpriseProcessStatus == "B") {
    const inputString = match[0];
    let jobIde = inputString.replace("&jobId=", "");
          let payLoadState = {
            jobId: jobIde,
            tabValue: match[1]
          };
    navigate(extractedString, { state: payLoadState });
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
  } else {
    danger("The notification you're trying to access has expired.");
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
    return;
  }
  } else if (notification.callToActionUrl.includes("Applied")) {
    const paramDelimiter = "&";
  const startIndex = notification.callToActionUrl.indexOf(searchString);
  const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
  const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
  const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
  const statusResponse = await handleJobsApiCall("A", match[1]);
  if (statusResponse[0]?.enterpriseProcessStatus == "R") {
    const inputString = match[0];
    let jobIde = inputString.replace("&jobId=", "");
          let payLoadState = {
            jobId: jobIde,
            tabValue: match[1]
          };
    navigate(extractedString, { state: payLoadState });
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
  } else {
    danger("The notification you're trying to access has expired.");
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
    getUpdatedUnreadCount();
    return;
  }
}
} else {
  const path = notification.callToActionUrl != "" ? new URL(notification.callToActionUrl).pathname : "";
// const startIndex = notification.callToActionUrl.indexOf(searchString);
// const extractedString = notification.callToActionUrl.substring(startIndex);
if (notification.notificationValue?.includes("interview feedback") ||
notification.notificationValue?.includes("received an interview invite") ||
notification.notificationValue?.includes("rescheduled") ||
notification.notificationValue?.includes("cancelled") ||
notification.notificationValue?.includes("upcoming interview")) {
   interviewDetails(path, notification);
} else if (notification.callToActionUrl?.includes("/message-list")) {
  let array = notification.callToActionUrl.split("?");
  if (array?.length > 1) {
    let _searchParams = array[1].split("&");
    let _candidateId = _searchParams[0].split("=")[1];
    let _enterpriseId = _searchParams[1].split("=")[1];
    ApiCalls.get(
      "candidates/messages/0/500"
    )
    .then((response) => {
      let isExpired = false;
      if (response?.length) {
        let _currentChat = response?.filter((_chat)=>(_chat.unreadCount > 0 && _chat.messageFrom === _candidateId && _chat.enterpriseId === _enterpriseId));
        if (_currentChat?.length) {
          let timestamp1 = moment(_currentChat[0].lastMessageTimestamp).format("YYYY-MM-DDTHH:mm");
          let timestamp2 = moment(notification.createdTimestamp).utc().format("YYYY-MM-DDTHH:mm");
          if (timestamp1 !== timestamp2) {
            isExpired = true;
            danger("The notification you're trying to access has expired.");
          }
        } else {
          isExpired = true;
          danger("The notification you're trying to access has expired.");
        }
      } else {
        isExpired = true;
        danger("The notification you're trying to access has expired.");
      }
      if (!isExpired) {
        if (window.location.pathname.includes("/message-list")) {
          navigate(path);
          window.location.reload();
        } else {
          navigate(path);
        }
      }
    }).catch((err)=>{});
  }
} else if (notification.callToActionUrl?.includes("candidate/job-details/") && notification.notificationValue?.includes("shortlisted")) {
  let job_id = notification.callToActionUrl.split("job-details/")[1];
  ApiCalls.get(`${process.env.REACT_APP_API_URL}candidates/job/${job_id}`)
  .then((response) => {
      if (response.enterpriseProcessStatus !== "S") {
          danger("The notification you're trying to access has expired.");
      } else {
          if (window.location.pathname.includes("candidate/job-details/")) {
            navigate(path);
            window.location.reload();
          } else {
            navigate(path);
          }
      }
  }).catch((err)=>{});
} else {
  navigate(path);
};
props.setNotificationListDrawer(false);
setselectedbutton("All");
getUpdatedUnreadCount();
}
};
const handleAllNotificationRead = () => {
  ApiCalls.put("candidates/notifications/C")
    .then((response) => {
      getNotificationsDtoList();
      props.setNotificationListDrawer(false);
      setselectedbutton("All");
      props.setNotificationsCount(0);
    })
    .catch(function (error) {});
};
const handleJobsApiCall = async(status, jobId) => {
  try {
    const response = await ApiCalls.get("candidates/pool/" + status + `?jobId=${jobId}`);
    return response;
  } catch (error) {
    return error?.errorMessage;
  }
};

  const goToViewReason = () => {
    navigate("../my-job-details/e2581da8-166f-4194-9812-28b3f352049a");
  };
  // const isMinutesOrHours = (date) => {
  //   const date1 = moment.utc(date).toDate();
  //   const date2 = new Date();
  //   const diffInMs = Math.abs(date2 - date1);
  //   const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  //   const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  //   if (diffInMinutes == 0) {
  //     return "just now";
  //   }
  //   if (diffInMinutes <= 60) {
  //     return diffInMinutes + "min ago";
  //   } else {
  //     return diffInHours + "hr ago";
  //   }
  // };
  // const isMinutesOrHours = (date) => {
  //   // Convert the input date to a Moment.js object
  //   const momentDate = moment.utc(date);

  //   // Get the current date and time as a Moment.js object
  //   const now = moment.utc();

  //   // Calculate the difference between the two dates in minutes
  //   const diffInMinutes = now.diff(momentDate, 'minutes');

  //   // Handle the case where the date is less than a minute ago
  //   if (diffInMinutes < 1) {
  //     return 'just now';
  //   }

  //   // Handle the case where the date is less than an hour ago
  //   if (diffInMinutes < 60) {
  //     return `${diffInMinutes} mins ago`;
  //   }

  //   // Handle the case where the date is earlier than today
  //   const diffInHours = Math.floor(diffInMinutes / 60);
  //   if (diffInHours <= 24) {
  //     return `${diffInHours} hours ago`;
  //   }

  //   // Handle the case where the date is yesterday
  //   // if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
  //   //   return `yesterday ${momentDate.format('h:mm A')}`;
  //   // }

  //   // // Handle the case where the date is earlier than yesterday
  //   // return momentDate.format('MMM D, YYYY h:mm A');
  // };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);
  // let yesterdayformat =
  // console.log(formatDate(currentDateWeekFormat));
  // console.log(formatDate("2023-05-02T13:54:08.000+00:00"));
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.notificationListDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
      if (selectedbutton == "All") {
        getNotificationsDtoList();
      } else {
        getUnreadNotificationsDtoList();
      }
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
    const handleResize = () => {
      setContainerHeight(window.innerHeight * 0.8);
  };
  window.addEventListener('resize', handleResize);
  return () => {
      window.removeEventListener('resize', handleResize);
  };
  }, [props.notificationListDrawer]);
  useEffect(() => {
    if (document.getElementById("recite-header") != null) {
      setBodyPaddingTop(true);
    }
  }, [document.getElementById("recite-header")]);
  return (
    <>
      {/* <Helmet>
        <title>Notifications | hireHQ</title>
        <meta
          name="description"
          content="Edit Profile | hireHQ"
        />
        <link
          rel="simplify"
          href="https://candidate.simplifycareers.com/"
        />
      </Helmet> */}
      <Drawer
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.notificationListDrawer}
        onClose={() => {
          props.setNotificationListDrawer(false);
          setselectedbutton("All");
          props.closeBackpopups();
        }}
        className="notification-drawer"
      >
        <Container
          lg={12}
          md={12}
          xs={12}
          className="posted-align-view bg-white p-0"
        >
          {isLoading && <Loader />}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{ px: 2 }}
            className={`m-auto notification-align p-l-0 p-r-0 notify-list responsive-tablet-view ${
              bodyPaddingTop ? "pt-80" : "notification-responsive"
            }`}
          >
            <Grid sx={{ pt: 2 }} className="display-flex justify-between align-center file-upload">
              <Grid
                className="display-flex align-center contact-responsive"
              >
                <Grid
                  onClick={() => {
                    props.setNotificationListDrawer(false);
                    setselectedbutton("All");
                  props.closeBackpopups();
                }}
                  className="mt-7 cursor-hover"
                  style={{ color: "#98A2B3" }}
                >
                  <Tooltip title="Collapse" placement="top">
                    <ChevronLeftIcon className="f-30 m-l-5" />
                  </Tooltip>
                </Grid>
                <Typography className="h3-text heading-text-color ml-20">Notifications</Typography>
              </Grid>
              {notifications?.length > 0 && props?.notificationsCount > 0 &&
              <Typography className="f-14 link-color font-weight-500 pr-15 rating-percent-align" onClick={handleAllNotificationRead}>
                  Mark all as read
              </Typography>}
            </Grid>
            <Grid className="pl-20 display-flex align-center mt-20 mb-20">
                  <Button
                    style={{ width: '80px', height: '25px' }}
                    className={`btn display-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "All" ? "all-unread-button-selected" : "all-unread-button"}`}
                    onClick={getNotificationsDtoList}
                    >
                    <span className="ml-20 mr-20">All</span>
                  </Button>
                  <Button
                    style={{ width: '80px', height: '25px' }}
                    className={`ml-8 btn display-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "Unread" ? "all-unread-button-selected" : "all-unread-button"}`}
                    onClick={getUnreadNotificationsDtoList}
                    >
                    <span className="ml-20 mr-20">Unread</span>
                  </Button>
                </Grid>
                <div style={{ height: containerHeight }}>
                <InfiniteScroll
                dataLength={allnotifications.length}
                next={() => fetchMoreData()}
                hasMore={loadMore}
                inverse={false}
                height={containerHeight}
                >
                  <Grid item className="">
                {allnotifications &&
                  allnotifications.map((notification, index) => {
                  // notification.callToActionUrl =
                  //   "/candidate/job-details/eyJqb2JpZCI6ImI1YjFlNTg0LWE1ZDUtNGIyMS1hNDgwLTc4OTBmOGY1ZDAwMiIsImlzZnJvbSI6Im15Sm9icyIsInRhYlBvc2l0aW9uIjowfQ==";
                  return (
                    <Grid className={`p-15 border-bottom-gray5 cursor-hover pl-31 pr-31 ${notification.notificationStatus == "U" && "secondary-bg-color display-flex justify-between"}`} key={index} onClick={()=> notification.callToActionUrl ? (appOrigin !== "N" ? handleNotificationNavigation(notification) : !notification.callToActionUrl.includes("/interviewDetails") && handleNotificationNavigation(notification)) : handleReadNotification(notification)}>
                      <Typography className="display-flex">
                        <Typography>
                          <Typography className="notification-default-icon justify-center align-center display-flex">
                            <NotificationDefaultIcon
                              width="40px"
                              height="40px"
                              className="rect-svg-icons"
                            />
                            {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                        </Typography>
                      </Typography>
                      <Typography className="pl-10">
                          <Typography className="">
                            <Typography className="body-medium-text m-0 notification-value">
                              {notification.notificationValue}
                            </Typography>
                          </Typography>
                          {/* {notification.callToActionUrl && (
                                    <Typography
                                      className="text-decoration-none"
                                      // onClick={()=> handleNotificationNavigation(notification)}
                                    >
                                      <Typography
                                        className="cursor-hover"
                                        tabIndex={0}
                                      >
                                        <span className="link-color f-12 font-weight-400">
                                          {
                                            notification.callToActionUrl.includes(
                                              "Offered"
                                            )
                                              ? "View Offer"
                                              : notification.callToActionUrl.includes(
                                                  "Onboarded"
                                                )
                                              ? "View Job"
                                              : notification.callToActionUrl.includes(
                                                  "Offboarded"
                                                )
                                              ? "View Job"
                                              : notification.callToActionUrl.includes(
                                                  "/interviewDetails"
                                                ) && appOrigin !== "N"
                                              ? "View Interview"
                                              : notification.callToActionUrl.includes(
                                                  "/find-jobs"
                                                )
                                              ? "View Job"
                                              : notification.callToActionUrl.includes("job-details") ?
                                              "View Job" : ""
                                            // for job-details page
                                          }
                                        </span>
                                      </Typography>
                                    </Typography>
                                  )} */}
                        <Typography className="l-h-1 posted-align-view">
                          {/* {getdays(notification?.createdTimestamp)} */}
                          {formatDate(new Date()) ===
                          formatDate(notification?.createdTimestamp) ? (
                            <Typography className="supporting-text-light p-t-5">
                              {isMinutesOrHours(notification?.createdTimestamp)}
                              {/* {moment(new Date()).startOf('hour').fromNow()} */}
                            </Typography>
                          ) : (
                            <Typography>
                              {formatDate(onedayBefore) ===
                              formatDate(notification?.createdTimestamp) ? (
                                <Typography className="supporting-text-light p-t-5">
                                  {notification?.createdTimestamp === null ||
                                  notification?.createdTimestamp === ""
                                    ? "-"
                                    : `Yesterday`}
                                </Typography>
                              ) : (
                                <>
                                  {formatDate(currentDateWeekFormat) <=
                                  formatDate(notification?.createdTimestamp) ? (
                                    <Typography className="supporting-text-light p-t-5">
                                      {notification?.createdTimestamp ===
                                        null ||
                                      notification?.createdTimestamp === ""
                                        ? "-"
                                        : moment(
                                            notification?.createdTimestamp
                                          ).format("MMM DD, YYYY")}
                                    </Typography>
                                  ) : (
                                    <Typography className="supporting-text-light p-t-5">
                                      {notification?.createdTimestamp ===
                                        null ||
                                      notification?.createdTimestamp === ""
                                        ? "-"
                                        : moment(
                                            notification?.createdTimestamp
                                          ).format("MMM DD, YYYY")}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Typography>
                          )}
                        </Typography>
                        </Typography>
                      </Typography>
                    {notification.notificationStatus == "U" &&
                      <Typography className="display-flex align-center ml-30">
                        <span className="unread-dot"></span>
                      </Typography>}
                    </Grid>
                    );
                  })}
                  {(selectedbutton === "Unread") && !allnotifications?.length &&
                    <Typography className="d-block p-10 mt-20">
                        <Typography className="l-h-1 cursor-default text-center">
                            <Typography>
                                <NoNotificationIcon width={220} height={220} />
                            </Typography>
                            <Typography className="py-20">
                                <span className="gray9 py-20 f-16 font-weight-600">
                                    You don’t have any notifications for now
                                </span>
                            </Typography>
                        </Typography>
                    </Typography>
                  }
                  </Grid>
                  </InfiniteScroll>
                </div>
          </Grid>
        </Container>
      </Drawer>
    </>
  );
}
