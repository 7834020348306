import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import { Nationalities } from "../data/nationalities";
import moment from "moment";
import { tz } from "moment-timezone";

export const formatDate = (date) => {
  var d;
  if (date === "0000-00-00" || date === "000000") {
    return "0000-00-00";
  }
  if (typeof date === "string" || typeof date === "number") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const formatDateYYYYMM = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month].join("");
  } else {
    return null;
  }
};

export const populateNationality = (key) => {
  for (var i = 0; i < Nationalities.length; i++) {
    var nationality = Nationalities[i];
    if (nationality.alpha_3_code === key) {
      return nationality.nationality;
    }
  }
};

export const getselectedNationality = (key) => {
  for (var i = 0; i < Nationalities.length; i++) {
    var nationality = Nationalities[i];
    if (nationality.alpha_3_code === key) {
      return nationality;
    }
  }
};

const jobType = {
  FT: "Full Time",
  PT: "Part Time",
  FL: "Contingent / Contract",
  IN: "Internship",
  OT: "Other",
};
const paymentTerm = {
  WK: "Per week",
  FN: "Per fortnight",
  MN: "Per month",
};
const paymentRate = {
  WK: "week",
  HR: "hour",
  MN: "month",
};

const getMinimumEducation = {
  SL: "School",
  DM: "Diploma",
  BR: "Bachelors",
  MT: "Masters",
  DC: "Doctorate",
};
export const getJobType = (jT) => {
  if (jT.includes(",")) {
    const workType = jT.split(",");
    // const workType = myArray[0].trim();
    if (workType.includes("FT")) {
      return jobType["FT"];
    }
    if (workType.includes("PT")) {
      return jobType["PT"];
    }
    if (workType.includes("FL")) {
      return jobType["FL"];
    }
    if (workType.includes("IN")) {
      return jobType["IN"];
    }
    if (workType.includes("OT")) {
      return jobType["OT"];
    }
    return jobType[firstPart];
  } else {
    return jobType[jT] ? jobType[jT] : "-";
  }
};

export const getPaymentTerm = (type) => {
  return paymentTerm[type] ? paymentTerm[type] : "-";
};

export const getEducation = (type) => {
  return getMinimumEducation[type] ? getMinimumEducation[type] : "-";
};
export const getPaymentRate = (type) => {
  return paymentRate[type] ? paymentRate[type] : "-";
};
export const getJobLocation = (city, state, country) => {
  const location = [];

  if (city) {
    location.push(city);
  }
  if (state) {
    location.push(state);
  }
  if (country) {
    location.push(country);
  }
  const finalLocation = location.join(", ");
  return finalLocation ? finalLocation : null;
};
export const isLocationTrue = (city, state, country) => {
  const location = [];

  if (city) {
    location.push(city);
  }
  if (state) {
    location.push(state);
  }
  if (country) {
    location.push(country);
  }
  const finalLocation = location.join(", ");
  return finalLocation ? true : false;
};
export const getLocation = (job) => {
  let location = "";
  if (job.city && job.state && job.country) {
    location = job.city + ", " + job.state + ", " + job.country;
  } else if (job.city && job.state && (job.country == null || job.country == "")) {
    location = job.city + ", " + job.state;
  } else if (job.state && job.country && (job.city == null || job.city == "")) {
    location = job.state + ", " + job.country;
  } else if (job.city && job.country && (job.state == null || job.state == "")) {
    location = job.city + ", " + job.country;
  } else if (job.state && (job.country == null || job.country == "") && (job.city == null || job.city == "")) {
    location = job.state;
  } else if (job.country && (job.city == null || job.city == "") && (job.state == null || job.state == "")) {
    location = job.country;
  } else if (job.city && (job.country == null || job.country == "") && (job.state == null || job.state == "")) {
    location = job.city;
  }
  return location;
};

export const handleSpaces = (event) => {
  let e = window.event || event;

  let key = e.keyCode;

  //space pressed

  if (key === 32) {
    if (event.target.value < 1) event.preventDefault();
  }
};
export const restrictText = (event) => {
  let e = window.event || event;
  let key = e.keyCode || e.which;
  if (
    (key >= 48 && key <= 57) || // numeric keys
    key == 8 || // backspace
    key == 9 || // tab
    key == 13 || // enter
    key == 27 || // escape
    (key >= 37 && key <= 40) // arrow keys
) {
    return true;
} else {
    e.preventDefault();
    return false;
}
};
export const checkLocation = (city, country) => {
  if ((city !== null && city !== "") || (country !== null && country !== "")) {
    return true;
  }
};
export const isMinutesOrHours = (date) => {
  // Convert the input date to a Moment.js object
  const momentDate = moment.utc(date);

  // Get the current date and time as a Moment.js object
  const now = moment.utc();

  // Calculate the difference between the two dates in minutes
  const diffInMinutes = now.diff(momentDate, "minutes");

  // Handle the case where the date is less than a minute ago
  if (diffInMinutes < 1) {
    return "just now";
  }

  // Handle the case where the date is less than an hour ago
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes < 2 ? 'minute' : 'minutes'} ago`;
  }

  // Handle the case where the date is earlier than today
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours <= 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  }

  // Handle the case where the date is yesterday
  // if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
  //   return `yesterday ${momentDate.format('h:mm A')}`;
  // }

  // // Handle the case where the date is earlier than yesterday
  // return momentDate.format('MMM D, YYYY h:mm A');
};

export const getFormattedSkills = (skills) => {
  let s = skills.split(",");
  if (s.length > 1) {
    let skl = "";
    s.map((o, i) => {
      skl += o + ", ";
      if ((i + 1) % 2 === 0) {
        skl += "\n  ";
      }
    });

    return skl.trim().slice(0, -1);
  }

  return skills;
};

export const latestJobs = (lastUpdatedTimestamp) => {
  const lastUpdated = moment(lastUpdatedTimestamp).format();

  var current = moment().startOf("day");

  let calculateDays = moment.duration(current.diff(lastUpdated)).asDays();
  if (calculateDays <= 1) {
    return "Latest";
  }
};

export const getJobPostedAgo = (createdTimestamp) => {
  if (createdTimestamp) {
    const fromNow = moment(createdTimestamp).fromNow();
    return "Posted " + fromNow;
  }
  return "";
};

export const getdays = (createdTimestamp) => {
  if (createdTimestamp) {
    const fromNow = moment(createdTimestamp).fromNow();
    return " " + fromNow;
  }
  return "";
};

export const convertHtmlToText = (passHtmlBlock) => {
  const text = passHtmlBlock.toString();
  return text.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
};

export const formatMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const workDuration = (workedFrom, workedTo) => {
  var currentDate = moment().format("YYYY-MM-DD");
  var start = moment(workedFrom, "YYYY-MM-DD");
  var end = moment(
    workedTo === "1900-01-01" ? currentDate : workedTo,
    "YYYY-MM-DD"
  );
  var years = end.diff(start, "years");
  var months = end.diff(start, "months");
  var output = "";
  if (years > 0 && months === 0) {
    output = years === 0 ? "" : `${years} ${years === 1 ? "Year " : "Years "}`;
  }
  if (years === 0 && months > 0) {
    output =
      months === 0
        ? "1 month"
        : `${months} ${months === 1 ? "month " : "months "}`;
  }
  if (years === 0 && months === 0) output = "1 month";
  if (years > 0 && months % years === 0) {
    output = `${years} ${years === 1 ? "Year " : "Years "}`;
  }
  if (years > 0 && months % 12 > 0) {
    output =
      `${years} ${years === 1 ? "Year " : "Years "}` +
      `${months % 12} ${months % 12 === 1 ? "month " : "months "}`;
  }

  return output;
};

export const checkIsImage = (url) => {
  // let x = mediaFile != undefined && mediaFile?.split(".").pop().split("?")[0];
  const parsedUrl = url ? new URL(url) : "";
  let extension = parsedUrl?.pathname?.split(".")?.pop();
  if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
    return true;
  }
  return false;
};

export const getValidFullName = (fullname) => {
  if (fullname) {
    let name = fullname.split(" ");
    if (name[name.length - 1] === "null" || name[name.length - 1] === "undefined") {
      return name.slice(0, name.length - 1).join(" ");
    }
    return fullname;
  }
  return "";
};

export const getColorForChar= (letter) => {
  let firstLetter = letter.trim().substring(0, 1);
  switch (firstLetter.toUpperCase()) {
    case 'A':
      return "#5e0926";
    case 'B':
      return "#5e0926";
    case 'C':
      return "#5a0e67";
    case 'D':
      return "#14678c";
    case 'E':
      return "#6200EA";
    case 'F':
      return "#103e65";
    case 'G':
      return "#0f4964";
    case 'H':
      return "#0c8094";
    case 'I':
      return "#094b2b";
    case 'J':
      return "#224d01";
    case 'K':
      return "#48590d";
    case 'L':
      return "#6b6309";
    case 'M':
      return "#6b550f";
    case 'N':
      return "#985e11";
    case 'O':
      return "#5e2210";
    case 'P':
      return "#e03f3f";
    case 'Q':
      return "#6D4C41";
    case 'R':
      return "#1E88E5";
    case 'S':
      return "#9b3d0d";
    case 'T':
      return "#5d0e1e";
    case 'U':
      return "#455A64";
    case 'V':
      return "#046370";
    case 'W':
      return "#066e3b";
    case 'X':
      return "#39700b";
    case 'Y':
      return "#84a219";
    case 'Z':
      return "#6b6207";
    default:
      return "#000000"; // black color
  }
};
export const autoPopulate = (fullname) => {
  let currentDate = moment(new Date()).format("MMM DD, YYYY");
  let fullName = [];
  fullName = fullname
    ? getValidFullName(fullname).split(" ")
    : localStorage.getItem("fullname")?.split(" ");
  let fname = document.getElementsByName("fname");
  let lname = document.getElementsByName("lname");
  let cdate = document.getElementsByName("cdate");
  if (fname && fname.length === 1 && !fname[0].value) {
    fname[0].value = fullName[0];
  }
  if (lname && lname.length === 1 && !lname[0].value) {
    if (fullName.length > 1) {
      lname[0].value = fullName[1];
    }
  }
  if (cdate && cdate.length === 1) {
    cdate[0].value = currentDate;
  }
};
export const convertPayment = (value) => {
  if (value >= 99999) {
    return Math.floor(value / 1000) + "K";
  }
  return value;
  // if (value % 1000 == 0) {
  //   return value / 1000 + "K";
  // } else {
  //   return value;
  // }
};
export const getWorkType = (type) => {
  switch (type) {
    case "R":
      return "Remote";
    case "O":
      return "Onsite";
    case "H":
      return "Hybrid";
    default:
      return "-";
  }
};
export const getJobExpLevel = (level) => {
  switch (level) {
    case "IN":
      return "Internship";
    case "EL":
      return "Entry Level";
    case "AT":
      return "Associate";
    case "ML":
      return "Mid-Senior Level";
    case "DR":
      return "Director";
    case "ET":
      return "Executive";
    default:
      return "-";
  }
};
export const getFullPaymentTerm = (type) => {
  switch (type) {
    case "WK":
      return "per week";
    case "HR":
      return "per hour";
    case "MN":
      return "per month";
    case "DA":
      return "per day";
    default:
      return "-";
  }
};
export const hourlyRateBudget = (
  budgetMinimumHourlyBasis,
  budgetMaximumHourlyBasis,
  currency
) => {
  if ((budgetMinimumHourlyBasis == 0 && budgetMaximumHourlyBasis == 0) || (!budgetMinimumHourlyBasis && !budgetMaximumHourlyBasis)) {
    return "";
  }
  let minBudget = budgetMinimumHourlyBasis ? budgetMinimumHourlyBasis : (budgetMinimumHourlyBasis === null || budgetMinimumHourlyBasis === "" ? "" : "0");
  let maxBudget = budgetMaximumHourlyBasis ? budgetMaximumHourlyBasis : "";
  return `${currency} ${minBudget}${(minBudget && maxBudget) ? " - " : ""}${maxBudget} / hour`;
};
export const getFormattedWorkType = (viewAll, worktype) => {
  let formatted_workType = [];
  if (worktype?.length) {
    if (worktype.includes("FT")) {
      formatted_workType.push("Full Time");
    }
    if (worktype.includes("PT")) {
      formatted_workType.push("Part Time");
    }
    if (worktype.includes("FL")) {
      formatted_workType.push("Contingent / Contract");
    }
    if (worktype.includes("IN")) {
      formatted_workType.push("Internship");
    }
    if (worktype.includes("OT")) {
      formatted_workType.push("Other");
    }
    if (!viewAll && formatted_workType.length > 1) {
      formatted_workType = formatted_workType.slice(0, 1);
    }
    return formatted_workType.toString();
  } else {
    return "-";
  }
};
export const timeGreetings = () => {
  const day = new Date();
  const hr = day.getHours();
  if (hr >= 0 && hr < 12) {
    return "Good Morning!";
  } else if (hr >= 12 && hr < 17) {
    return "Good Afternoon!";
  } else {
    return "Good Evening!";
  }
};
export const getFormattedTime = (value) => {
  const dateObj = moment(value).local().format("h:mm A");
  const timeParts = dateObj.split(":");
  // console.log(dateObj.)
  let hours = timeParts[0];
  const minutes = timeParts[1];
  const getAmPm = dateObj.split(" ");
  const amPM = getAmPm[1];
  const timeString = `${hours}:${minutes}`;
  return timeString;
};
export const gettimeZoneOffset = () => {
  let TimeZone = "";
    try {
      TimeZone = moment().tz(tz.guess()).format("z");
    } catch (err) {
      console.log(err);
    }
  // var rightNow = new Date();
  // let standatardTime = String(String(rightNow).split("(")[1]).split(")")[0];
  // standatardTime = standatardTime.split(" ");
  // let TimeZone = `${standatardTime[0].substring(
  //   0,
  //   1
  // )}${standatardTime[1].substring(0, 1)}${standatardTime[2].substring(0, 1)}`;
  return TimeZone;
  // console.log(zone);
};
export const LightTooltip = styled(({ className, ...props }) => {
  console.log(className, { ...props });
  return <Tooltip {...props} arrow position="bottom" trigger="mouseenter" animation="fade" classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#1D2939 !important',
    opacity: '1 !important',
    boxShadow: `${theme.shadows[1]} !important`,
  },
}));

export const PreferredCommunicationTooltip = ({ children }) => (
  <Tooltip
    placement="top"
    trigger="mouseenter"
    animation="fade"
    arrow
    title={
      <Typography className="font-weight-400 f-12">
        This contact information will be used for all platform communications with you
      </Typography>
    }
  >
    {children}
  </Tooltip>
);

export const setPlatformTheme = (theme_obj, appOrigin) => {
  let primaryColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#150349");
  let secondaryColor = (appOrigin === "N" ? "#EAF4FF" : appOrigin === "H" ? "#F0FFFA" : "#EEEDFE");
  let hoverButtonColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#FF9F7C");
  let hoverButtonTextColor = "#150349";
  let headingTextColor = "#101828";
  let mainTextColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#150349");
  let listHoverColor = "#EEEDFE";
  try {
    let themes = theme_obj ? JSON.parse(theme_obj) : [];
    let selectedTheme = themes?.filter((theme)=>(theme.isSelected));
    if (selectedTheme.length) {
      selectedTheme = selectedTheme[0];
      primaryColor = "#" + selectedTheme.primaryColor;
      secondaryColor = "#" + selectedTheme.secondaryColor;
      hoverButtonColor = "#" + selectedTheme.hoverButtonColor;
      hoverButtonTextColor = "#" + selectedTheme.hoverButtonTextColor;
      headingTextColor = "#" + selectedTheme.headingTextColor;
      mainTextColor = "#" + selectedTheme.mainTextColor;
      listHoverColor = "#" + selectedTheme.listHoverColor;
      localStorage.setItem("theme_obj", JSON.stringify({ "p_color": selectedTheme.primaryColor, "s_color": selectedTheme.secondaryColor, "h_color": selectedTheme.hoverButtonColor, "h_text_color": selectedTheme.hoverButtonTextColor }));
    }
  } catch (err) {}
  localStorage.setItem("primaryColor", primaryColor);
  document.documentElement.style.setProperty(
    "--primary-color",
    primaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-color",
    hoverButtonColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-text-color",
    hoverButtonTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--heading-text-color",
    headingTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--main-text-color",
    mainTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--list-hover-color",
    listHoverColor,
    "important"
  );
};
export const getDurationValue = (duration) => {
  let value = "";
  if (duration && duration.length > 1) {
    try {
      value = parseInt(duration.slice(0, duration.length - 1));
    } catch (err) {}
  }
  return value;
};
export const getDurationPeriod = (duration) => {
  let value = "";
  if (duration && duration.length > 1) {
    let period = duration.slice(duration.length - 1, duration.length);
    switch (period) {
      case "D":
          value = "Day(s)";
          break;
      case "W":
          value = "Week(s)";
          break;
      case "M":
          value = "Month(s)";
          break;
      case "Y":
          value = "Year(s)";
          break;
    }
  }
  return value;
};
export const replaceKey = (originalKey, newKeys, modifiedResponse) => {
  if (modifiedResponse.includes(originalKey)) {
    modifiedResponse.push(...newKeys);
    let index = modifiedResponse.indexOf(originalKey);
    if (index > -1) {
      modifiedResponse.splice(index, 1); // Remove the original key
    }
  }
};
export const removeQueryParams = (query) => {
  const baseUrl = window.location.origin + window.location.pathname;
  const updatedBaseUrl = baseUrl + (query ? query : "");
  window.history.replaceState({}, document.title, updatedBaseUrl);
};
